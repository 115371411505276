import Vue from 'vue'
import Router from 'vue-router'
// in development-env not use lazy-loading, because lazy-loading too many pages will cause webpack hot update too slow. so only in production use lazy-loading;
// detail: https://panjiachen.github.io/vue-element-admin-site/#/lazy-loading

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

//所有权限通用路由表
//如首页和登录页和一些不用权限的公用页面
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/error/401', component: () => import('@/views/error/401'), hidden: true },
  { path: '/error/404', component: () => import('@/views/error/404'), hidden: true },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    name: 'Dashboard',
    hidden: true,
    children: [{
      path: 'dashboard',
      component: () => import('@/views/dashboard/index')
    }]
  },
  {
    path: '',
    component: Layout,
    name: 'Info',
    hidden: true,
    children: [{
      path: '/info',
      component: () => import('@/views/info/index')
    }]
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ],
  }
]


//实例化vue的时候只挂载constantRouter
export default new Router({
  mode: 'history',
  routes: constantRouterMap
});


//异步挂载的路由
//动态需要根据权限加载的路由表
//meta无permissions字段则不做权限控制
export const asyncRouterMap = [

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'User',
    meta: { title: '用户管理', permissions: ['userList'], icon: 'users' },
    children: [
      {
        path: '/user/list',
        name: 'Users',
        component: () => import('@/views/user/index'),
        meta: { title: '用户列表', permissions: ['userList'] }
      }
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Finance',
    meta: { title: '经营数据', permissions: ['financeDay','financeBill','financeReport','liveWalletReport','dataAnalysis','comprehensiveAnalysis'], icon: 'finance' },
    children: [
      {
        path: '/finance/day',
        name: 'FinanceDay',
        component: () => import('@/views/finance/day'),
        meta: { title: '营业日结', permissions: ['financeDay'] }
      },
      {
        path: '/finance/bill',
        name: 'FinanceBill',
        component: () => import('@/views/finance/bill'),
        meta: { title: '订单流水', permissions: ['financeBill'] }
      },
      {
        path: '/finance/report',
        name: 'FinanceReport',
        component: () => import('@/views/finance/report'),
        meta: { title: '财务报表', permissions: ['financeReport'] }
      },
      {
        path: '/finance/liveWalletReport',
        name: 'liveWalletReport',
        component: () => import('@/views/finance/liveWalletReport'),
        meta: { title: '现场钱包报表', permissions: ['liveWalletReport'] }
      },
      {
        path: '/finance/dataAnalysis',
        name: 'DataAnalysis',
        component: () => import('@/views/finance/dataAnalysis'),
        meta: { title: '数据分析', permissions: ['dataAnalysis'] }
      },
      {
        path: '/finance/comprehensiveAnalysis',
        name: 'ComprehensiveAnalysis',
        component: () => import('@/views/finance/comprehensiveAnalysis'),
        meta: { title: '综合分析', permissions: ['comprehensiveAnalysis'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'EPayLinks',
    meta: { title: '易票联管理', permissions: ['merchantList'], icon: 'shop' },
    children: [
      {
        path: '/merchant/list',
        name: 'MerchantList',
        component: () => import('@/views/epaylinks/merchant'),
        meta: { title: '商户进件', permissions: ['merchantList'] }
      },
      {
        path: '/merchantBusiness/list',
        name: 'MerchantBusinessList',
        hidden: true,
        component: () => import('@/views/epaylinks/merchantBusiness'),
        meta: { title: '商户业务' }
      },
      {
        path: '/merchant/withdrawalList',
        name: 'merchantWithdrawalList',
        hidden: true,
        component: () => import('@/views/epaylinks/merchantWithdrawal'),
        meta: { title: '商户提现' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'AgriculturalBankActivities',
    meta: { title: '农行活动管理', permissions: ['activitiesList','channelShopList'], icon: 'shop' },
    children: [
      {
        path: '/activities/list',
        name: 'ActivitiesList',
        component: () => import('@/views/agriculturalBankActivities/index'),
        meta: { title: '活动列表', permissions: ['activitiesList'] }
      },
      {
        path: '/activities/orderRecord',
        name: 'ActivitiesOrderRecord',
        hidden: true,
        component: () => import('@/views/agriculturalBankActivities/orderRecord'),
        meta: { title: '订单记录' }
      },
      {
        path: '/activities/channelShopList',
        name: 'ChannelShopList',
        component: () => import('@/views/agriculturalBankActivities/channelShopList'),
        meta: { title: '渠道商家列表', permissions: ['channelShopList'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Order',
    meta: { title: '订单管理', permissions: ['orderList','compensateList','sideboardOrderList'], icon: 'order' },
    children: [
      {
        path: '/order/list',
        name: 'OrderList',
        component: () => import('@/views/order/index'),
        meta: { title: '订单列表', permissions: ['orderList'] }
      },
      {
        path: '/order/compensate',
        name: 'Compensate',
        component: () => import('@/views/order/compensate'),
        meta: { title: '赔付列表', permissions: ['compensateList'] }
      },
      {
        path: '/order/sideboardOrder',
        name: 'SideboardOrder',
        component: () => import('@/views/order/sideboardOrder'),
        meta: { title: '投餐列表', permissions: ['sideboardOrderList'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Comment',
    meta: { title: '评价管理', permissions: ['commentList','commentAppeal'], icon: 'comment' },
    children: [
      {
        path: '/comment/list',
        name: 'CommentList',
        component: () => import('@/views/comment/index'),
        meta: { title: '评价列表', permissions: ['commentList'] }
      },
      {
        path: '/comment/appeal',
        name: 'CommentAppeal',
        component: () => import('@/views/comment/appeal'),
        meta: { title: '申诉列表', permissions: ['commentAppeal'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'School',
    meta: { title: '学校管理', permissions: ['buildingList','schoolList','canteenList','shopCategory','shopList','ledgerRole'], icon: 'shop' },
    children: [
      {
        path: '/building/index',
        name: 'BuildingList',
        component: () => import('@/views/building/index'),
        meta: { title: '楼栋列表', permissions: ['buildingList'] }
      },
      {
        path: '/building/floor',
        name: 'BuildingFloor',
        hidden: true,
        component: () => import('@/views/building/floor'),
        meta: { title: '楼层列表' }
      },
      {
        path: '/school/index',
        name: 'SchoolList',
        component: () => import('@/views/school/index'),
        meta: { title: '学校列表', permissions: ['schoolList'] }
      },
      {
        path: '/school/canteen',
        name: 'SchoolCanteen',
        component: () => import('@/views/school/canteen'),
        meta: { title: '食堂列表', permissions: ['canteenList'] }
      },
      {
        path: '/shop/shopCategory',
        name: 'ShopCategory',
        component: () => import('@/views/shop/shopCategory'),
        meta: { title: '店铺分类', permissions: ['shopCategory'] }
      },
      {
        path: '/shop/index',
        name: 'ShopList',
        component: () => import('@/views/shop/index'),
        meta: { title: '店铺列表', permissions: ['shopList'] }
      },
      {
        path: '/shop/shopCashierDevice',
        name: 'ShopCashierDevice',
        hidden: true,
        component: () => import('@/views/shop/shopCashierDevice'),
        meta: { title: '收银设备管理' }
      },
      {
        path: '/school/ledgerRole',
        name: 'LedgerRole',
        component: () => import('@/views/school/ledgerRole'),
        meta: { title: '分账角色列表', permissions: ['ledgerRole'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Withdraw',
    meta: { title: '提现管理', permissions: ['withdrawApply','shopWithdraw','distributemanWithdraw','sendmanWithdraw','userWithdraw'], icon: 'withdraw' },
    children: [
      {
        path: '/finance/withdraw',
        name: 'WithdrawApply',
        component: () => import('@/views/finance/withdraw'),
        meta: { title: '提现申请', permissions: ['withdrawApply'] }
      },
      {
        path: '/shop/withdraw',
        name: 'ShopWithdraw',
        component: () => import('@/views/shop/withdraw'),
        meta: { title: '店铺提现', permissions: ['shopWithdraw'] }
      },
      {
        path: '/distributeman/withdraw',
        name: 'DistributemanWithdraw',
        component: () => import('@/views/distributeman/withdraw'),
        meta: { title: '分餐员提现', permissions: ['distributemanWithdraw'] }
      },
      {
        path: '/sendman/withdraw',
        name: 'SendmanWithdraw',
        component: () => import('@/views/sendman/withdraw'),
        meta: { title: '派送员提现', permissions: ['sendmanWithdraw'] }
      },
      {
        path: '/user/withdraw',
        name: 'UserWithdraw',
        component: () => import('@/views/user/withdraw'),
        meta: { title: '用户提现', permissions: ['userWithdraw'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'SP',
    meta: { title: '分账管理', permissions: ['ledgerRole','spOrder','revenueLog'], icon: 'shop' },
    children: [
      {
        path: '/school/ledgerRolePhone',
        name: 'LedgerRolePhone',
        hidden: true,
        component: () => import('@/views/school/ledgerRolePhone'),
        meta: { title: '绑定的手机号列表' }
      },
      {
        path: '/school/spOrder',
        name: 'spOrder',
        component: () => import('@/views/school/spOrder'),
        meta: { title: '分账订单', permissions: ['spOrder'] }
      },
      {
        path: '/school/revenueLog',
        name: 'revenueLog',
        component: () => import('@/views/school/revenueLog'),
        meta: { title: '分账收入记录', permissions: ['ledgerRole','spOrder','revenueLog'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Cashier',
    meta: { title: '收银台管理', permissions: ['ledgerRole','cashierOrderList','cashierOrderSplitLog'], icon: 'shop' },
    children: [
      {
        path: '/cashier/orderList',
        name: 'cashierOrderList',
        component: () => import('@/views/cashier/orderList'),
        meta: { title: '订单列表', permissions: ['cashierOrderList'] }
      },
      {
        path: '/cashier/orderSplitLog',
        name: 'cashierOrderSplitLog',
        component: () => import('@/views/cashier/orderSplitLog'),
        meta: { title: '分账明细', permissions: ['ledgerRole','cashierOrderList','cashierOrderSplitLog'] }
      }
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Catered',
    meta: { title: '包餐管理', permissions: ['systemCateredTimeList','schoolCateredPriceList','importCateredStudent','cateredStudentList','cateredStudentOrder','teacherCardType','teacherCardList','teacherCardBalanceLog','teacherCardOrder'], icon: 'catered' },
    children: [
      {
        path: '/catered/systemCateredTimeList',
        name: 'systemCateredTimeList',
        component: () => import('@/views/catered/systemCateredTimeList'),
        meta: { title: '系统包餐时间列表', permissions: ['systemCateredTimeList'] }
      },
      {
        path: '/catered/schoolCateredPriceList',
        name: 'schoolCateredPriceList',
        component: () => import('@/views/catered/schoolCateredPriceList'),
        meta: { title: '学校包餐价格列表', permissions: ['schoolCateredPriceList'] }
      },
      {
        path: '/catered/importCateredStudent',
        name: 'importCateredStudent',
        component: () => import('@/views/catered/importCateredStudent'),
        meta: { title: '导入包餐学生', permissions: ['importCateredStudent'] }
      },
      {
        path: '/catered/cateredStudentList',
        name: 'cateredStudentList',
        component: () => import('@/views/catered/cateredStudentList'),
        meta: { title: '包餐学生列表', permissions: ['cateredStudentList'] }
      },
      {
        path: '/catered/cateredStudentOrder',
        name: 'cateredStudentOrder',
        component: () => import('@/views/catered/cateredStudentOrder'),
        meta: { title: '包餐消费记录', permissions: ['cateredStudentList','cateredStudentOrder'] }
      },
      {
        path: '/catered/teacherCardType',
        name: 'teacherCardType',
        component: () => import('@/views/catered/teacherCardType'),
        meta: { title: '教师卡类型', permissions: ['teacherCardType'] }
      },
      {
        path: '/catered/teacherCardList',
        name: 'teacherCardList',
        component: () => import('@/views/catered/teacherCardList'),
        meta: { title: '教师卡列表', permissions: ['teacherCardList'] }
      },
      {
        path: '/catered/teacherCardBalanceLog',
        name: 'teacherCardBalanceLog',
        component: () => import('@/views/catered/teacherCardBalanceLog'),
        meta: { title: '教师卡余额明细', permissions: ['teacherCardBalanceLog'] }
      },
      {
        path: '/catered/teacherCardOrder',
        name: 'teacherCardOrder',
        component: () => import('@/views/catered/teacherCardOrder'),
        meta: { title: '教师卡消费记录', permissions: ['teacherCardList','teacherCardOrder'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'ShopFinance',
    meta: { title: '店铺财务管理', permissions: ['shopFinanceList'], icon: 'shopFinance' },
    children: [
      {
        path: '/shop/financeList',
        name: 'shopFinanceList',
        component: () => import('@/views/shop/financeList'),
        meta: { title: '店铺财务列表', permissions: ['shopFinanceList'] }
      },
      {
        path: '/shop/cashierDeviceOrder',
        name: 'shopCashierDeviceOrder',
        hidden: true,
        component: () => import('@/views/shop/cashierDeviceOrder'),
        meta: { title: '扫码机明细' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Shop',
    meta: { title: '商家管理', permissions: ['goodsCategory','goodsList'], icon: 'goods' },
    children: [
      {
        path: '/shop/shopManager',
        name: 'ShopManager',
        hidden: true,
        component: () => import('@/views/shop/shopManager'),
        meta: { title: '店铺管理员' }
      },
      {
        path: '/shop/category',
        name: 'GoodsCategory',
        component: () => import('@/views/shop/category'),
        meta: { title: '分类列表', permissions: ['shopList', 'goodsCategory'] }
      },
      {
        path: '/shop/goods',
        name: 'GoodsList',
        component: () => import('@/views/shop/goods'),
        meta: { title: '商品列表', permissions: ['shopList', 'goodsCategory', 'goodsList'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Coupon',
    meta: { title: '优惠券管理', permissions: ['couponList','couponPackage','couponPackageOrder'], icon: 'coupon' },
    children: [
      {
        path: '/coupon/index',
        name: 'CouponList',
        component: () => import('@/views/coupon/index'),
        meta: { title: '优惠券列表', permissions: ['couponList'] }
      },
      // {
      //   path: '/shop/activity',
      //   name: 'ActivityList',
      //   component: () => import('@/views/shop/shopActivity'),
      //   meta: { title: '商品活动' }
      // },
      {
        path: '/coupon/couponPackage',
        name: 'CouponPackage',
        component: () => import('@/views/coupon/couponPackage'),
        meta: { title: '优惠券套餐', permissions: ['couponPackage'] }
      },
      {
        path: '/coupon/couponPackageOrder',
        name: 'CouponPackageOrder',
        component: () => import('@/views/coupon/couponPackageOrder'),
        meta: { title: '优惠券套餐订单', permissions: ['couponPackageOrder'] }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Building',
  //   meta: { title: '楼栋管理', permissions: ['building'], icon: 'building' },
  //   children: [
  //     {
  //       path: '/building/index',
  //       name: 'BuildingList',
  //       component: () => import('@/views/building/index'),
  //       meta: { title: '楼栋列表', permissions: ['building'] }
  //     },
  //     {
  //       path: '/building/floor',
  //       name: 'BuildingFloor',
  //       hidden: true,
  //       component: () => import('@/views/building/floor'),
  //       meta: { title: '楼层列表', permissions: ['building'] }
  //     },
  //   ]
  // },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Sendman',
    meta: { title: '派送员管理', permissions: ['sendmanList','sendmanSetting'], icon: 'sendman' },
    children: [
      {
        path: '/sendman/index',
        name: 'SendmanList',
        component: () => import('@/views/sendman/index'),
        meta: { title: '派送员列表', permissions: ['sendmanList'] }
      },
      {
        path: '/sendman/setting',
        name: 'SendmanSetting',
        component: () => import('@/views/sendman/setting'),
        meta: { title: '配送设置', permissions: ['sendmanSetting'] }
      },
      {
        path: '/sendman/settle',
        name: 'SendmanSettle',
        hidden: true,
        component: () => import('@/views/sendman/settle'),
        meta: { title: '结算' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Device',
    meta: { title: '餐柜管理', permissions: ['deviceList','deviceBox','deviceOrder','deviceUse'], icon: 'cupboard' },
    children: [
      {
        path: '/device/index',
        name: 'DeviceIndex',
        component: () => import('@/views/device/index'),
        meta: { title: '设备列表', permissions: ['deviceList'] }
      },
      {
        path: '/device/box',
        name: 'DeviceBox',
        component: () => import('@/views/device/box'),
        meta: { title: '箱子列表', permissions: ['deviceBox'] }
      },
      {
        path: '/device/order',
        name: 'DeviceOrder',
        component: () => import('@/views/device/order'),
        meta: { title: '投餐订单', permissions: ['deviceOrder'] }
      },
      {
        path: '/device/use',
        name: 'DeviceUse',
        component: () => import('@/views/device/use'),
        meta: { title: '投餐统计', permissions: ['deviceUse'] }
      },
      {
        path: '/device/useDetail',
        name: 'DeviceUseDetail',
        hidden: true,
        component: () => import('@/views/device/useDetail'),
        meta: { title: '投餐统计详情' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Member',
    meta: { title: '会员管理', permissions: ['memberLevel','memberOrder'], icon: 'member' },
    children: [
      {
        path: '/member/level',
        name: 'MemberLevel',
        component: () => import('@/views/member/level'),
        meta: { title: '等级设置', permissions: ['memberLevel'] }
      },
      {
        path: '/member/order',
        name: 'MemberOrder',
        component: () => import('@/views/member/order'),
        meta: { title: '购买订单', permissions: ['memberOrder'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Wallet',
    meta: { title: '钱包管理', permissions: ['walletCharge','walletOrder','walletRechargeRecovery','shopWallet','distributemanWallet','sendmanWallet','userWallet','liveWallet','timingRechargeLiveWallet'], icon: 'wallet' },
    children: [
      {
        path: '/wallet/charge',
        name: 'WalletCharge',
        component: () => import('@/views/wallet/charge'),
        meta: { title: '充值设置', permissions: ['walletCharge'] }
      },
      {
        path: '/wallet/order',
        name: 'WalletOrder',
        component: () => import('@/views/wallet/order'),
        meta: { title: '充值订单', permissions: ['walletOrder'] }
      },
      {
        path: '/wallet/rechargeRecovery',
        name: 'WalletRechargeRecovery',
        component: () => import('@/views/wallet/rechargeRecovery'),
        meta: { title: '充值回收', permissions: ['walletRechargeRecovery'] }
      },
      {
        path: '/wallet/shopWallet',
        name: 'ShopWallet',
        component: () => import('@/views/wallet/shopWallet'),
        meta: { title: '店铺钱包', permissions: ['shopWallet'] }
      },
      {
        path: "/wallet/shopWalletLog",
        name: "ShopWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/shopWalletLog"),
        meta: { title: "店铺钱包明细" }
      },
      {
        path: '/wallet/distributemanWallet',
        name: 'DistributemanWallet',
        component: () => import('@/views/wallet/distributemanWallet'),
        meta: { title: '分拣员钱包', permissions: ['distributemanWallet'] }
      },
      {
        path: "/wallet/distributemanWalletLog",
        name: "DistributemanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/distributemanWalletLog"),
        meta: { title: "分拣员钱包明细" }
      },
      {
        path: '/wallet/sendmanWallet',
        name: 'SendmanWallet',
        component: () => import('@/views/wallet/sendmanWallet'),
        meta: { title: '配送员钱包', permissions: ['sendmanWallet'] }
      },
      {
        path: "/wallet/sendmanWalletLog",
        name: "SendmanWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/sendmanWalletLog"),
        meta: { title: "配送员钱包明细" }
      },
      {
        path: '/wallet/userWallet',
        name: 'UserWallet',
        component: () => import('@/views/wallet/userWallet'),
        meta: { title: '用户钱包', permissions: ['userWallet'] }
      },
      {
        path: "/wallet/userWalletLog",
        name: "UserWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/userWalletLog"),
        meta: { title: "用户钱包明细" }
      },
      {
        path: "/wallet/userWalletLog2",
        name: "UserWalletLog2",
        hidden: true,
        component: () => import("@/views/wallet/userWalletLog2"),
        meta: { title: "用户钱包明细" }
      },
      {
        path: '/wallet/liveWallet',
        name: 'LiveWallet',
        component: () => import('@/views/wallet/liveWallet'),
        meta: { title: '现场钱包', permissions: ['liveWallet'] }
      },
      {
        path: "/wallet/liveWalletLog",
        name: "LiveWalletLog",
        hidden: true,
        component: () => import("@/views/wallet/liveWalletLog"),
        meta: { title: "现场钱包明细" }
      },
      {
        path: "/wallet/timingRechargeLiveWallet",
        name: "TimingRechargeLiveWallet",
        component: () => import("@/views/wallet/timingRechargeLiveWallet"),
        meta: { title: "定时充值现场钱包", permissions: ["timingRechargeLiveWallet"] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'LiveWalletReconciliation',
    meta: { title: '现场钱包对账', permissions: ['liveWalletCollectionRecord','liveWalletUserRecord'], icon: 'wallet' },
    children: [
      {
        path: '/liveWallet/collectionRecord',
        name: 'CollectionRecords',
        component: () => import('@/views/wallet/collectionRecord'),
        meta: { title: '收款记录', permissions: ['liveWalletCollectionRecord'] }
      },
      {
        path: '/liveWallet/userRecord',
        name: 'UserRecord',
        component: () => import('@/views/wallet/liveWalletUserRecord'),
        meta: { title: '用户记录', permissions: ['liveWalletUserRecord'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Advert',
    meta: { title: '广告管理', permissions: ['advertList','advertTimingDisplay','deviceAd','banner','bannerTimingDisplay','bestSelling','bestSellingTimingDisplay','flashSaleTime','flashSaleGoods','dailySpecialGoods','recommendShop'], icon: 'advert' },
    children: [
      {
        path: '/advert/index',
        name: 'AdvertIndex',
        component: () => import('@/views/advert/index'),
        meta: { title: '小程序广告', permissions: ['advertList'] }
      },
      {
        path: '/advert/advertTimingDisplay',
        name: 'AdvertTimingDisplay',
        component: () => import('@/views/advert/advertTimingDisplay'),
        meta: { title: '小程序广告定时', permissions: ['advertTimingDisplay'] }
      },
      {
        path: '/advert/deviceAd',
        name: 'DeviceAd',
        component: () => import('@/views/advert/deviceAd'),
        meta: { title: '餐柜广告', permissions: ['deviceAd'] }
      },
      {
        path: '/advert/banner',
        name: 'Banner',
        component: () => import('@/views/advert/banner'),
        meta: { title: '轮播图设置', permissions: ['banner'] }
      },
      {
        path: '/advert/bannerTimingDisplay',
        name: 'BannerTimingDisplay',
        component: () => import('@/views/advert/bannerTimingDisplay'),
        meta: { title: '轮播图定时', permissions: ['bannerTimingDisplay'] }
      },
      {
        path: '/advert/bestSelling',
        name: 'BestSelling',
        component: () => import('@/views/advert/bestSelling'),
        meta: { title: '精选好店', permissions: ['bestSelling'] }
      },
      {
        path: '/advert/bestSellingTimingDisplay',
        name: 'BestSellingTimingDisplay',
        component: () => import('@/views/advert/bestSellingTimingDisplay'),
        meta: { title: '精选好店定时', permissions: ['bestSellingTimingDisplay'] }
      },
      {
        path: '/advert/flashSaleTime',
        name: 'FlashSaleTime',
        component: () => import('@/views/advert/flashSaleTime'),
        meta: { title: '限时抢购时间', permissions: ['flashSaleTime'] }
      },
      {
        path: '/advert/flashSaleGoods',
        name: 'FlashSaleGoods',
        component: () => import('@/views/advert/flashSaleGoods'),
        meta: { title: '限时抢购商品', permissions: ['flashSaleGoods'] }
      },
      {
        path: '/advert/addFlashSaleGoods',
        name: 'AddFlashSaleGoods',
        hidden: true,
        component: () => import('@/views/advert/addFlashSaleGoods'),
        meta: { title: '新增限时抢购商品' }
      },
      {
        path: '/advert/dailySpecialGoods',
        name: 'DailySpecialGoods',
        component: () => import('@/views/advert/dailySpecialGoods'),
        meta: { title: '天天特价商品', permissions: ['dailySpecialGoods'] }
      },
      {
        path: '/advert/addDailySpecialGoods',
        name: 'AddDailySpecialGoods',
        hidden: true,
        component: () => import('@/views/advert/addDailySpecialGoods'),
        meta: { title: '新增天天特价商品' }
      },
      {
        path: '/advert/recommendShop',
        name: 'RecommendShop',
        component: () => import('@/views/advert/recommendShop'),
        meta: { title: '推荐店铺', permissions: ['recommendShop'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Site',
    meta: { title: '建站管理', permissions: ['join','feedback','company','agreement','systemConfig'], icon: 'site' },
    children: [
      {
        path: '/site/join',
        name: 'join',
        component: () => import('@/views/site/join'),
        meta: { title: '加盟申请', permissions: ['join'] }
      },
      {
        path: '/site/feedback',
        name: 'feedback',
        component: () => import('@/views/site/feedback'),
        meta: { title: '意见反馈', permissions: ['feedback'] }
      },
      {
        path: '/site/company',
        name: 'company',
        component: () => import('@/views/site/company'),
        meta: { title: '公司介绍', permissions: ['company'] }
      },
      {
        path: '/site/agreement',
        name: 'agreement',
        component: () => import('@/views/site/agreement'),
        meta: { title: '服务协议', permissions: ['agreement'] }
      },
      {
        path: '/site/agreementDetail',
        name: 'agreementDetail',
        hidden: true,
        component: () => import('@/views/site/agreementDetail'),
        meta: { title: '服务协议详情' }
      },
      {
        path: '/site/systemConfig',
        name: 'systemConfig',
        component: () => import('@/views/site/systemConfig'),
        meta: { title: '系统配置', permissions: ['systemConfig'] }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Data',
  //   meta: { title: '数据管理', permissions: ['banner','about'], icon: 'data' },
  //   children: []
  // },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Saleman',
    meta: { title: '业务员管理', permissions: ['salemanList'], icon: 'staff' },
    children: [
      {
        path: '/saleman/index',
        name: 'Saleman',
        component: () => import('@/views/saleman/index'),
        meta: { title: '业务员列表', permissions: ['salemanList'] }
      },
      {
        path: '/saleman/income',
        name: 'SalemanIncome',
        hidden: true,
        component: () => import('@/views/saleman/income'),
        meta: { title: '提成' }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Distributeman',
    meta: { title: '分餐员管理', permissions: ['distributemanList','distributemanOrder'], icon: 'distribute' },
    children: [
      {
        path: '/distributeman/index',
        name: 'DistributemanIndex',
        component: () => import('@/views/distributeman/index'),
        meta: { title: '分餐员列表', permissions: ['distributemanList'] }
      },
      {
        path: '/distributeman/order',
        name: 'DistributemanOrder',
        component: () => import('@/views/distributeman/order'),
        meta: { title: '分餐订单', permissions: ['distributemanOrder'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'attendance',
    meta: { title: '考勤打卡', permissions: ['attendanceConfig','attendanceCodeList','attendanceDistributeman','attendanceSendman'], icon: 'attendance' },
    children: [
      {
        path: '/attendance/config',
        name: 'attendanceConfig',
        component: () => import('@/views/attendance/config'),
        meta: { title: '考勤配置', permissions: ['attendanceConfig'] }
      },
      {
        path: '/attendance/codeList',
        name: 'attendanceCodeList',
        component: () => import('@/views/attendance/codeList'),
        meta: { title: '考勤码列表', permissions: ['attendanceCodeList'] }
      },
      {
        path: '/attendance/distributeman',
        name: 'attendanceDistributeman',
        component: () => import('@/views/attendance/distributeman'),
        meta: { title: '分拣员考勤', permissions: ['attendanceDistributeman'] }
      },
      {
        path: '/attendance/sendman',
        name: 'attendanceSendman',
        component: () => import('@/views/attendance/sendman'),
        meta: { title: '配送员考勤', permissions: ['attendanceSendman'] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'System',
    meta: { title: '权限管理', permissions: ['adminList','permissionList'], icon: 'system' },
    children: [
      {
        path: '/admin/list',
        name: 'Admin',
        component: () => import('@/views/admin/index'),
        meta: { title: '账号列表', permissions: ['adminList'] }
      },
      {
        path: "/permission/list",
        name: "Permissions",
        component: () => import("@/views/permission/index"),
        meta: { title: "权限菜单", permissions: ["permissionList"] }
      },
    ]
  },

  {
    path: '',
    component: Layout,
    alwaysShow: true,
    name: 'Log',
    meta: { title: '日志管理', permissions: ['logList'], icon: 'log' },
    children: [
      {
        path: '/log/list',
        name: 'LogList',
        component: () => import('@/views/log/index'),
        meta: { title: '日志列表', permissions: ['logList'] }
      },
    ]
  },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'CCB',
  //   meta: { title: '龙存管管理', permissions: ['userAccount','platAccount'], icon: 'CCB' },
  //   children: [
  //     // {
  //     //   path: '/ccb/list',
  //     //   name: 'CCB',
  //     //   component: () => import('@/views/ccb/index'),
  //     //   meta: { title: '登记簿', permissions: ['ccb'] }
  //     // },
  //     {
  //       path: '/ccb/userAccount',
  //       name: 'userAccount',
  //       component: () => import('@/views/ccb/userAccount'),
  //       meta: { title: '学校账户', permissions: ['userAccount'] }
  //     },
  //     {
  //       path: '/ccb/platAccount',
  //       name: 'platAccount',
  //       component: () => import('@/views/ccb/platAccount'),
  //       meta: { title: '平台账户', permissions: ['platAccount'] }
  //     },
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Content',
  //   meta: { title: '内容管理', permissions: ['banner','about'], icon: 'content' },
  //   children: [
  //     {
  //       path: '/banner/list',
  //       name: 'Banners',
  //       component: () => import('@/views/banner/index'),
  //       meta: { title: '轮播图设置', permissions: ['banner'] }
  //     },
  //     {
  //       path: '/about',
  //       name: 'About',
  //       component: () => import('@/views/about/index'),
  //       meta: { title: '关于我们', permissions: ['about'] }
  //     }
  //   ]
  // },

  // {
  //   path: '',
  //   component: Layout,
  //   alwaysShow: true,
  //   name: 'Others',
  //   meta: { title: '其他', icon: 'others' },
  //   children: [
  //     {
  //       path: '/map',
  //       name: 'Map',
  //       component: () => import('@/views/others/map'),
  //       meta: { title: '地图' }
  //     }
  //   ]
  // },
  { path: '*', redirect: '/error/404', hidden: true },
]
